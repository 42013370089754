<template>
  <div
    ref="componentElement"
    class="group/select group/input relative mb-2"
    :data-disabled="isDisabled"
  >
    <RequiredLabel
      v-if="!noLabel"
      :id="id + '_combobox_label'"
      class="block bg-base-2 text-sm font-medium group-focus-within/select:text-primary-1 group-data-disabled/select:text-neutral-4 group-focus-within/select:group-data-disabled/select:text-base-1"
      :class="
        invertedLabel ? 'bg-transparent text-base-2 contrast:text-base-1' : ''
      "
      :required="required"
      @click="comboboxElement?.focus()"
    >
      {{ label }}
    </RequiredLabel>
    <div
      :id="id + '_combobox'"
      ref="comboboxElement"
      :aria-controls="id + '_combobox_dialog'"
      aria-haspopup="dialog"
      :aria-expanded="dialogVisible"
      :aria-labelledby="id + '_combobox_label'"
      class="flex h-10 cursor-pointer flex-nowrap items-center justify-end rounded bg-base-2 px-2 text-base-1 outline outline-1 outline-base-1 focus-within:outline-primary-1 focus:outline-2 group-data-disabled/select:outline-neutral-6 contrast:group-data-disabled/select:outline-base-1 contrast:group-data-disabled/select:outline-dashed"
      role="combobox"
      :aria-disabled="undefinedIfNotTrue(isDisabled)"
      tabindex="0"
      @click="toggleDialogVisible(!dialogVisible)"
      @keydown.down.prevent.self="toggleDialogVisible(true)"
    >
      <div
        v-if="multiple && Array.isArray(statusSelectedElements)"
        class="absolute left-0 ml-2 flex w-11/12 flex-row flex-nowrap gap-1 overflow-clip"
      >
        <div
          v-for="chipElement in statusSelectedElements"
          :key="chipElement?.toString()"
          class="py-0.5 bg-primary-8 border border-primary-7 contrast:border-base-1 flex items-center rounded group-data-disabled/select:border-neutral-4 group-data-disabled/select:text-neutral-3 group-data-disabled/select:bg-neutral-8"
        >
          <div class="pointer-events-none cursor-auto px-1 tracking-tight">
            <p
              class="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-[0.2rem] align-middle text-xs"
              :title="getStringValue(chipElement)"
            >
              {{
                statusModelValue !== undefined
                  ? chipElement
                  : listMap.get(chipElement)
              }}
            </p>
          </div>
          <ControlButton
            :id="`${id}_remove_selected_element`"
            button-style="button-compact"
            :disabled="isDisabled"
            class="rounded-full text-xs p-0.5 mt-0.5 mr-1"
            :aria-label="localization.unselectItem"
            @click.stop="removeSelectedElement(chipElement)"
          >
            <template #icon>
              <IconPzo name="close" />
            </template>
          </ControlButton>
        </div>
      </div>
      <div
        v-else
        class="absolute left-0 ml-2 w-11/12 overflow-clip whitespace-nowrap group-focus-within/select:text-primary-1 group-data-disabled/select:text-neutral-4"
      >
        {{ statusModelValue ?? listMap.get(statusSelectedElements) }}
      </div>
      <InputValidationMessage
        v-if="!validationResult.valid"
        :id="id + '_error_message'"
        :message="validationResult.message"
        :static="true"
      />
      <div
        class="z-10 flex grow-0 items-stretch justify-center group-focus-within/select:text-primary-1 group-data-disabled/select:text-neutral-4"
      >
        <div
          class="w-8 self-stretch bg-gradient-to-r from-transparent to-base-2"
        ></div>
        <div class="flex items-center gap-1 bg-base-2">
          <ControlButton
            v-show="!noUnselectIcon && isSomethingSelected()"
            :id="`${id}_unselect_all`"
            button-style="button-compact"
            :inverted="false"
            :aria-label="
              multiple ? localization.unselectAll : localization.unselectItem
            "
            :disabled="isDisabled"
            @click.stop="unselectAll()"
            @mousedown.prevent=""
          >
            <template #icon>
              <IconPzo name="close" />
            </template>
          </ControlButton>
          <ControlButton
            v-if="!dialogVisible"
            :id="`${id}_show_dialog`"
            button-style="button-compact"
            :inverted="false"
            :tabindex="-1"
            :aria-label="localization.openArrowLabel"
          >
            <template #icon>
              <IconPzo name="expand-more" />
            </template>
          </ControlButton>
          <ControlButton
            v-else
            :id="`${id}_close_dialog`"
            button-style="button-compact"
            :inverted="false"
            :tabindex="-1"
            :aria-label="localization.closeArrowLabel"
            @click.stop="toggleDialogVisible(false)"
          >
            <template #icon>
              <IconPzo name="expand-less" />
            </template>
          </ControlButton>
        </div>
      </div>

      <ResponsiveFullscreen
        v-show="dialogVisible"
        :id="id + '_combobox_dialog'"
        ref="dialogElement"
        :aria-labelledby="id + '_combobox_dialog_title'"
        role="dialog"
        class="flex cursor-default flex-col drop-shadow-md sm:absolute sm:left-0 sm:top-16 sm:z-30 sm:h-fit sm:max-h-96 sm:rounded sm:border sm:border-base-1"
        aria-modal="true"
        @click.stop=""
        @keydown.esc.prevent.stop="toggleDialogVisible(false)"
      >
        <InputValidationMessage
          v-if="!validationResult.valid && dialogVisible"
          :id="id + '_error_message'"
          :message="validationResult.message"
          class="px-2 sm:hidden"
        />
        <h1
          :id="id + '_combobox_dialog_title'"
          class="sm:hidden text-sm border-b border-b-neutral-7"
        >
          {{ label }}
        </h1>

        <div v-if="!noFilter" class="grid grid-cols-1 gap-2 px-2 sm:flex">
          <div class="sm:grow">
            <InputTextfield
              :id="id + '_combobox_search'"
              ref="searchElement"
              v-model="searchText"
              type="text"
              :label="localization.search"
              :disabled="false"
            >
              <template #icon>
                <IconPzo name="search" />
              </template>
            </InputTextfield>
          </div>
          <div
            v-if="multiple"
            class="flex w-full flex-row rounded sm:w-auto sm:flex-col sm:gap-2"
          >
            <ControlButton
              :id="`${id}_select_all`"
              button-style="panel-button-narrow"
              :disabled="isDisabled"
              @click="listbox?.selectAll()"
            >
              {{ localization.selectAll }}
            </ControlButton>
            <ControlButton
              :id="`${id}_unselect_all`"
              button-style="panel-button-narrow"
              :disabled="isDisabled"
              @click="listbox?.unselectAll()"
            >
              {{ localization.unselectAll }}
            </ControlButton>
          </div>
        </div>
        <slot name="additionalContent"></slot>

        <InputListbox
          :id="id + '_combobox_listbox'"
          ref="listbox"
          v-slot="slotProps"
          v-model="selectedElements"
          :list="filteredList"
          :list-key="listKey"
          :list-value="listValue"
          :multiple="multiple"
          :disabled="isDisabled"
          :show-selection="false"
          :class="listboxClass"
          :no-unselect="noUnselect"
          :aria-label="`${label} - ${localization.listLabel}`"
        >
          <InputCheckbox
            :id="`${id}_checkbox_option_${replaceWhiteSpaces(
              slotProps.value,
              '_',
            )}`"
            :model-value="slotProps.selected"
            tabindex="-1"
            :label="slotProps.value.toString()"
            :disabled="isDisabled"
            class="px-2 py-4 sm:py-2"
            :disable-bottom-margin="true"
            @update:model-value="slotProps.toggle()"
          />
        </InputListbox>
        <ControlPanel class="sm:hidden">
          <ControlButton
            :id="`${id}_close_dialog_fullscreen`"
            :inverted="true"
            @click.stop="toggleDialogVisible(false)"
          >
            {{ localization.closeButtonLabel }}
          </ControlButton>
        </ControlPanel>
      </ResponsiveFullscreen>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, watch, inject } from "vue";
import { onClickOutside, useFocusWithin } from "@vueuse/core";
import InputTextfield from "./InputTextfield.vue";
import ControlPanel from "./ControlPanel.vue";
import ControlButton from "./ControlButton.vue";
import InputListbox from "./InputListbox.vue";
import RequiredLabel from "./RequiredLabel.vue";

import ResponsiveFullscreen from "./ResponsiveFullscreen.vue";
import { useFocusTrap } from "../composables/useFocusTrap";
import { filterContains } from "../composables/stringUtils";
import { usePopup } from "../composables/usePopup";
import InputCheckbox from "./InputCheckbox.vue";
import type { FormValidation, ModelValidation } from "../types/formValidation";
import { useFieldValidation } from "../composables/useFieldValidation";
import {
  withValidators,
  required as requiredValidator,
} from "../composables/validators";
import { undefinedIfNotTrue } from "../composables/attributes";
import InputValidationMessage from "./InputValidationMessage.vue";
import { formDisabledKey } from "../types/disabled";
import IconPzo from "./IconPzo.vue";
import { replaceWhiteSpaces } from "../composables/stringUtils";

interface Localization {
  selectAll: string;
  unselectAll: string;
  unselectItem: string;
  search: string;
  openArrowLabel: string;
  closeArrowLabel: string;
  closeButtonLabel: string;
  listLabel: string;
}

interface Props {
  id: string;
  list: Record<string | number, unknown>[];
  listKey: string;
  listValue: string;
  label?: string;
  multiple?: boolean;
  // inferred type is broken after setting default value to undefined
  // eslint-disable-next-line vue/require-default-prop
  modelValue?: string | string[] | number | number[] | boolean | boolean[];
  localization?: Localization;
  invertedLabel?: boolean;
  formValidation?: FormValidation;
  required?: boolean;
  disabled?: boolean;
  noFilter?: boolean;
  modelValidators?: ModelValidation[];
  listboxClass?: string;
  statusModelValue?: string | string[];
  noLabel?: boolean;
  noUnselectIcon?: boolean;
  noUnselect?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  localization: () => {
    return {
      selectAll: "Zaznacz wszystko",
      unselectAll: "Odznacz wszystko",
      unselectItem: "Usuń zaznaczenie",
      search: "Wyszukaj",
      openArrowLabel: "Otwórz listę wyboru",
      closeArrowLabel: "Zamknij listę wyboru",
      closeButtonLabel: "Zamknij",
      listLabel: "lista wyboru",
      labelInverted: false,
    };
  },
  formValidation: undefined,
  noFilter: false,
  modelValidators: undefined,
  listboxClass: "",
  statusModelValue: undefined,
  required: false,
  noLabel: false,
  label: undefined,
  noUnselectIcon: false,
  noUnselect: false,
});

const formDisabled = inject(formDisabledKey, false);
const isDisabled = computed(() => {
  return formDisabled || props.disabled;
});

const emit = defineEmits<{
  (
    e: "update:modelValue",
    value:
      | string
      | string[]
      | number
      | number[]
      | boolean
      | boolean[]
      | undefined,
  ): void;
  (e: "closed"): void;
}>();

const dialogElement = ref<InstanceType<typeof ResponsiveFullscreen>>();
const comboboxElement = ref<HTMLElement>();
const componentElement = ref<HTMLElement>();
const searchElement = ref<InstanceType<typeof InputTextfield>>();

const { focused: componentFocused } = useFocusWithin(componentElement);

watch(componentFocused, (newValue) => {
  if (!newValue) {
    initializeValidation();
  }
});

const searchText = ref("");
const dialogVisible = ref(false);
const listbox = ref<InstanceType<typeof InputListbox>>();
const focusTrap = useFocusTrap(dialogElement);
const popup = usePopup(
  dialogElement,
  comboboxElement,
  dialogVisible,
  [0, 0.25],
  "fixed",
  true,
);

const listMap = computed(() => {
  const map = new Map(
    props.list.map((option) => [
      option[props.listKey],
      option[props.listValue],
    ]),
  );
  return map;
});
const filteredList = computed(() => {
  if (!searchText.value) {
    return props.list;
  }

  return filterContains(searchText.value, props.list, [props.listValue]);
});
const selectedElements = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});
const statusSelectedElements = computed(() => {
  if (props.statusModelValue !== undefined) {
    return props.statusModelValue;
  }

  return selectedElements.value;
});

const allValidators = computed(() => {
  if (isDisabled.value) {
    return [];
  }
  return withValidators([], [[requiredValidator, props.required === true]]);
});

const { validationResult, initializeValidation, resetValidation } =
  useFieldValidation(
    props.id,
    selectedElements,
    allValidators,
    props.modelValidators,
    props.formValidation,
  );

onClickOutside(componentElement, () => toggleDialogVisible(false, false));

watch(
  () => filteredList.value,
  async (newValue, prevValue) => {
    if (
      (newValue.length === 0 && prevValue.length !== 0) ||
      (newValue.length !== 0 && prevValue.length === 0)
    ) {
      await nextTick();
      focusTrap.updateFocusableElements();
    }
  },
);

watch(selectedElements, () => {
  if (!props.multiple) {
    toggleDialogVisible(false, false);
  }
});

function getStringValue(key: string | number | boolean) {
  const unknownValue = listMap.value.get(key);
  if (
    typeof unknownValue === "number" ||
    typeof unknownValue === "string" ||
    typeof unknownValue === "boolean"
  ) {
    return unknownValue.toString();
  }
}

function unselectAll() {
  selectedElements.value = undefined;
}

function removeSelectedElement(element: string | number | boolean) {
  if (Array.isArray(selectedElements.value)) {
    // typescript bug woraround: https://github.com/microsoft/TypeScript/issues/44373
    if (typeof element === "number") {
      selectedElements.value = (selectedElements.value as number[]).filter(
        (val) => {
          return val !== element;
        },
      );
    } else {
      selectedElements.value = (selectedElements.value as string[]).filter(
        (val) => {
          return val !== element;
        },
      );
    }
  }
}

async function toggleDialogVisible(visible: boolean, focusCombobox = true) {
  if (dialogVisible.value !== visible) {
    dialogVisible.value = visible;
    popup.update();
    if (visible) {
      await nextTick();
      focusTrap.initFocusTrap();
      searchElement.value?.focus();
    } else if (focusCombobox) {
      focusTrap.clearFocusTrap();
      emit("closed");
      comboboxElement.value?.focus();
    } else {
      focusTrap.clearFocusTrap();
      emit("closed");
    }
  }
}

function isSomethingSelected() {
  if (Array.isArray(selectedElements.value)) {
    return selectedElements.value.length > 0;
  }
  return selectedElements.value;
}

defineExpose({ resetValidation });
</script>
